import React, { useEffect, useState } from 'react';
import Header from '../components/Header'
import KpiDropdown from '../components/antd/KpiDropdown'
import { useNavigate } from 'react-router-dom'
import axios from 'axios';
import { apiUrl } from '../Variables';
import toast, { Toaster } from 'react-hot-toast';
import Spinner from 'react-bootstrap/Spinner';

function DashboardScreen({ toggleLoggedInState }) {

  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [filterType, setFilterType] = useState('all')
  const [subscriptionType, setSubscriptionType] = useState('all')
  const [subscriptions, setSubscriptions] = useState([])

  // HANDLING DROPDOWN SELECTION FUNCTION ============================================================
  const handleFilterChange = (filter, subFilter) => {
    setFilterType(filter);
    setSubscriptionType(subFilter);
  };

  // HANDLING DROPDOWN SELECTION FUNCTION ============================================================


  // INITIALIZATION OF COMPONENT =====================================================================
  useEffect(() => {
    // Retrieve userInfo from localStorage
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (!userInfo || !userInfo.token) { console.error('User token not found.'); return; }
    const headers = { Authorization: `Bearer ${userInfo.token}`, };

    const featchData = async () => {
      setLoading(true);
      try {
        const res = await axios.get(`${apiUrl}/plan`);
        setSubscriptions(res.data);
        setLoading(false)
      } catch (error) {
        setLoading(false);
        console.log('*************************Retrieving Subscription Plans Failed!*************************\n', error);
        toast.error(`Error: ${error?.message || error?.response?.data}`);
      }
      try {
        const res = await axios.get(`${apiUrl}/user/user_report`, { headers });
        setUsers(res.data);
        setLoading(false)
      } catch (error) {
        setLoading(false);
        console.log('*************************Kpis fetching fail!*************************\n', error);
        toast.error(`Error: ${error?.message || error?.response?.data}`);
      }
    }; featchData();
  }, []);

  const paidPlanIds = subscriptions.filter(sub => sub.price > 0).map(sub => sub._id);
  const freemiumPlanId = subscriptions.find(sub => sub.price === 0)?._id;


  // INITIALIZATION OF COMPONENT =====================================================================


  // HELPER FUNCTIONS ================================================================================
  const filteredUsers = () => {
    let filteredData = users;
    if (filterType === 'all') {
      return users;
    } else if (filterType === 'new') {
      filteredData = users.filter(i => ((new Date() - new Date(i.createdAt)) / (1000 * 60 * 60 * 24)) <= 7);
    } else if (filterType === 'unpaid') {
      filteredData = filteredData.filter(i => i.plan._id === freemiumPlanId);
    } else if (filterType === 'paid') {
      filteredData = filteredData.filter(i => paidPlanIds.includes(i.plan._id));
    }
    if (subscriptionType && subscriptionType !== 'all') {
      filteredData = filteredData.filter(i => i.plan && i.plan.name.toLowerCase() === subscriptionType.toLowerCase());
    }
    return filteredData;
  };
  // Function to convert JSON array to CSV format

  const convertToCSV = (jsonArray) => {
    const unwantedHeaders = ["__v", "_id", "utilityData", "profile_image"];
    const csvRows = [];

    if (!jsonArray || jsonArray.length === 0) {
      return "";
    }

    const allHeaders = new Set();
    jsonArray.forEach(obj => {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          allHeaders.add(key); // Add top-level keys directly
        }
      }
    });

    const headers = Array.from(allHeaders).filter(header => !unwantedHeaders.some(unwanted => header.includes(unwanted)));

    csvRows.push(headers.join(','));

    for (const row of jsonArray) {
      const values = headers.map(header => {
        let value;
        if (header.includes('.')) {
          const parts = header.split('.');
          value = row;
          for (const part of parts) {
            value = value?.[part];
          }
        } else {
          value = row[header];
        }
        // Handle empty values
        if (value === undefined || value === null || value === "") {
          value = "N/A";
        }

        if (['createdAt', 'updatedAt', 'planValidation'].includes(header)) {
          if (value) {
            try {
              const dateObj = new Date(value);
              if (!isNaN(dateObj)) {
                dateObj.setHours(dateObj.getHours() + 8);
                value = dateObj.toISOString().replace(/T/, ' ').replace(/\..+/, '');
              } else {
                console.error(`Invalid date format for ${header}:`, value);
              }
            } catch (error) {
              console.error(`Error converting ${header}:`, value, error);
            }
          } else {
            value = "N/A";
          }
        } else if (typeof value === 'object' && value !== null) {
          value = Object.entries(value)
            .map(([key, val]) => `${key}: ${JSON.stringify(val)}`) // Stringify inner objects
            .join('\n');
        }
        const escaped = ('' + (value ?? '')).replace(/"/g, '""');
        return `"${escaped}"`;
      });
      csvRows.push(values.join(','));
    }

    return csvRows.join('\n');
  };
  // Function to download CSV file
  const downloadCSV = (csvString, fileName) => {
    const blob = new Blob([csvString], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  // Function to create filtered CSV file
  const handleDownload = (exportType) => {
    setFilterType('all');
    setSubscriptionType(null);
    switch (exportType) {
      case 'all': break;

      case 'unpaid': setFilterType('unpaid'); break;
      case 'unpaidNew': setFilterType('new'); setFilterType('unpaid'); break;

      case 'new': setFilterType('new'); break;
      case 'basicNew': setFilterType('new'); setSubscriptionType('basic'); break;
      case 'basicPaid': setFilterType('paid'); setSubscriptionType('basic'); break;

      case 'proNew': setFilterType('new'); setSubscriptionType('pro'); break;
      case 'proPaid': setFilterType('paid'); setSubscriptionType('pro'); break;

      case 'proPlusNew': setFilterType('new'); setSubscriptionType('pro+'); break;
      case 'proPlusPaid': setFilterType('paid'); setSubscriptionType('pro+'); break;

      case 'paid': setFilterType('paid'); setSubscriptionType('all'); break;
      default: break;
    }
    let retrievedUsers = filteredUsers()
    try {
      const csvString = convertToCSV(retrievedUsers.map(({ password, plan, ...rest }) => ({ ...rest, plan_name: plan?.name || "No Plan" })));
      downloadCSV(csvString, `ACEVA_Users_Data_${new Date()}.csv`);
    }
    catch (error) {
      console.log("***********************************Error in  handling download!***********************************\n", error)
      toast.error("Filtered Users does not exist!")
      return
    }

  };
  // HELPER FUNCTIONS ================================================================================


  return (
    <>
      <Header toggleLoggedInState={toggleLoggedInState} />
      <div><Toaster /></div>
      <div className="dashboard-section">
        <div className="container dashboard-container">

          {/* title */}
          <div className="dashboard-title">
            <h2>Admin Dashboard</h2>
            <button className='form-button' onClick={handleDownload}>
              Export Data
            </button>

            <div className="dashboard-title">
              <div className="dashboard-filters">
                <label htmlFor="user-type">User Type:</label>
                <select id="user-type" value={filterType} onChange={(e) => handleFilterChange(e.target.value, null)}>
                  <option value="all">All Users</option>
                  <option value="new">New Users</option>
                  <option value="paid">Paid Users</option>
                  <option value="unpaid">Unpaid Users</option>
                </select>

                {filterType === 'paid' && (
                  <>
                    <label htmlFor="subscription-type">Subscription Type:</label>
                    <select
                      id="subscription-type"
                      value={subscriptionType || ''}
                      onChange={(e) => handleFilterChange(filterType, e.target.value)}
                    >
                      <option value="all">All Subscriptions</option>
                      <option value="basic">Basic</option>
                      <option value="pro">Pro</option>
                      <option value="pro+">Pro+</option>
                    </select>
                  </>
                )}
                {filterType === 'new' && (
                  <>
                    <label htmlFor="subscription-type">Subscription Type:</label>
                    <select
                      id="subscription-type"
                      value={subscriptionType || ''}
                      onChange={(e) => handleFilterChange(filterType, e.target.value)}
                    >
                      <option value="all">All Subscriptions</option>
                      <option value="basic">Basic</option>
                      <option value="pro">Pro</option>
                      <option value="pro+">Pro+</option>
                      <option value="Freemium">Unpaid</option>
                    </select>
                  </>
                )}
              </div>
            </div>
          </div>


          {/* chart wrap */}
          <div className="dashboard-kpi-wrapper">
            {/* item */}
            <div className="dashboard-kpi-item">
              <div className="dashboard-kpi-header">
                <div className="dashboard-kpi-title">
                  <p>Existing Users</p>
                  <p className='dashboard-kpi-count'>{loading ? <Spinner animation="border" role="status" size='sm' /> : users.length}</p>
                </div>
                <div className="dashboard-dropdown">
                  <KpiDropdown />
                </div>
              </div>
            </div>

            {/* item */}
            <div className="dashboard-kpi-item">
              <div className="dashboard-kpi-header">
                <div className="dashboard-kpi-title">
                  <p>New Users</p>
                  <p className='dashboard-kpi-count'>{loading ? <Spinner animation="border" role="status" size='sm' /> : users.filter(i => ((new Date() - new Date(i.createdAt)) / (1000 * 60 * 60 * 24)) <= 7).length}</p>
                </div>
                <div className="dashboard-dropdown">
                  <KpiDropdown />
                </div>
              </div>
            </div>
            {/* item */}
            <div className="dashboard-kpi-item">
              <div className="dashboard-kpi-header">
                <div className="dashboard-kpi-title">
                  <p>Paid Users</p>
                  <p className='dashboard-kpi-count'>
                    {loading ? (
                      <Spinner animation="border" role="status" size="sm" />) : (users.filter((i) => i.plan?.price > 0).length)}
                  </p>
                </div>
                <div className="dashboard-dropdown">
                  <KpiDropdown />
                </div>
              </div>
            </div>

            {/* item */}
            <div className="dashboard-kpi-item">
              <div className="dashboard-kpi-header">
                <div className="dashboard-kpi-title">
                  <p>Unpaid Users</p>
                  <p className='dashboard-kpi-count'>{loading ? (<Spinner animation="border" role="status" size="sm" />) : (users.filter((i) => i.plan?.price <= 0).length)}
                  </p>
                </div>
                <div className="dashboard-dropdown">
                  <KpiDropdown />
                </div>
              </div>
              {/* <div className="dashboard-kpi-item-body">
                <BarChartComponent />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DashboardScreen