import { Button, Form, Input, Select } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import BreadCrumb from '../../components/blog/BreadCrumb';
import QuillEditor from '../../components/blog/QuillEditor';

const { Option } = Select;

function CreateBlogScreen() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [categories, setCategories] = useState([]);
  const [authors, setAuthors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [featuredImage, setFeaturedImage] = useState('');

  useEffect(() => {
    fetchCategoriesAndAuthors();
  }, []);

  // file upload handler
  const uploadFileHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    formData.append("upload_preset", "pewhglsn");
    formData.append("cloud_name", "dzo2vmjdy");

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      setUploadLoading(true);
      const { data } = await axios.post('https://api.cloudinary.com/v1_1/dzo2vmjdy/image/upload', formData, config);
      console.log('data', data);
      setFeaturedImage(data.url);
      setUploadLoading(false);
    } catch (error) {
      console.error(error);
      toast.error('Failed to upload image');
      setUploadLoading(false);
    }
  };

  const fetchCategoriesAndAuthors = async () => {
    try {
      const userInfo = localStorage.getItem('userInfo');
      const token = userInfo ? JSON.parse(userInfo).token : null;

      if (!token) {
        throw new Error('No authentication token found');
      }

      // Fetch categories
      const categoriesResponse = await fetch('https://gptsaas.aceva.com.my/api/blogCategories', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      // Fetch authors
      const authorsResponse = await fetch('https://gptsaas.aceva.com.my/api/authors', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!categoriesResponse.ok || !authorsResponse.ok) {
        throw new Error('Failed to fetch data');
      }

      const categoriesData = await categoriesResponse.json();
      const authorsData = await authorsResponse.json();

      setCategories(categoriesData.data || []);
      setAuthors(authorsData.data || []);
    } catch (error) {
      console.error('Error fetching data:', error);
      if (error.message === 'No authentication token found') {
        toast.error('Please login to continue');
        navigate('/login');
      } else {
        toast.error('Failed to load categories and authors');
      }
    } finally {
      setLoading(false);
    }
  };

  const onFinish = async (values) => {
    try {
      setIsSubmitting(true);

      const userInfo = localStorage.getItem('userInfo');
      const token = userInfo ? JSON.parse(userInfo).token : null;

      if (!token) {
        throw new Error('No authentication token found');
      }

      const data = {
        title: values.title,
        content: values.content,
        category: values.category,
        author: values.author,
        featuredImage: featuredImage // Use the stored image URL
      };

      if (values.videoUrl) {
        data.videoUrl = values.videoUrl;
      }

      const response = await axios.post('https://gptsaas.aceva.com.my/api/blogs', data, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.data.success) {
        throw new Error(response.data.message || 'Failed to create blog post');
      }

      toast.success('Blog post created successfully!');
      navigate('/blogs');
    } catch (error) {
      console.error('Error creating blog post:', error);
      if (error.message === 'No authentication token found') {
        toast.error('Please login to create a blog post');
        navigate('/login');
      } else {
        toast.error(error.message || 'Failed to create blog post. Please try again.');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.error('Form validation failed:', errorInfo);
    toast.error('Please check all required fields');
  };

  if (loading) {
    return (
      <>
        <Header />
        <div className="flex justify-center items-center py-20">
          <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-brand"></div>
          <span className="ml-4 text-lg text-paragraph">Loading...</span>
        </div>
      </>
    );
  }

  return (
    <>
      <Header />
      <div className="create-section">
        <div className="container">
          <div className="breadcrumb-wrap">
            <BreadCrumb />
          </div>
          <div className="form-header">
            <h2>Create New Blog Post</h2>
          </div>

          <Form
            form={form}
            name="create-blog"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <div className="create-container">
              <div className="create-wrap">
                <Form.Item
                  className='create-input'
                  label="Blog Title"
                  name="title"
                  rules={[
                    { required: true, message: 'Please input the title!' },
                    { min: 5, message: 'Title must be at least 5 characters long' },
                    { max: 200, message: 'Title cannot exceed 200 characters' }
                  ]}
                >
                  <Input placeholder="Enter the blog title" />
                </Form.Item>

                <Form.Item
                  className='create-input'
                  label="Video URL (Optional)"
                  name="videoUrl"
                  rules={[
                    { type: 'url', message: 'Please enter a valid URL!' }
                  ]}
                >
                  <Input placeholder="youtube.com/watch?v=example" />
                </Form.Item>

                <Form.Item
                  name="content"
                  rules={[
                    { 
                      required: true, 
                      message: 'Please input the content!',
                      validator: (_, value) => {
                        if (value === '<p><br></p>' || !value) {
                          return Promise.reject('Please input the content!');
                        }
                        return Promise.resolve();
                      }
                    }
                  ]}
                >
                  <QuillEditor />
                </Form.Item>

                <Form.Item>
                  <Button 
                    type="primary" 
                    htmlType="submit"
                    loading={isSubmitting}
                    disabled={isSubmitting || uploadLoading}
                  >
                    {isSubmitting ? 'Publishing...' : 'Publish'}
                  </Button>
                  <Button 
                    style={{ marginLeft: '10px' }} 
                    onClick={() => navigate('/blogs')}
                    disabled={isSubmitting}
                  >
                    Cancel
                  </Button>
                </Form.Item>
              </div>
              <div className="create-featured-image">
                <Form.Item
                  className='create-input'
                  label="Upload Featured Image"
                  name="image"
                  valuePropName="fileList"
                  getValueFromEvent={(e) => Array.isArray(e) ? e : e && e.fileList}
                >
                  <input
                    type="file"
                    onChange={uploadFileHandler}
                    accept="image/*"
                    disabled={uploadLoading}
                  />
                  {uploadLoading && <div>Uploading...</div>}
                  {featuredImage && <img src={featuredImage} alt="Featured" style={{marginTop: '10px', maxWidth: '200px'}} />}
                </Form.Item>

                <Form.Item
                  className='create-input'
                  label="Category"
                  name="category"
                  rules={[{ required: true, message: 'Please select a category!' }]}
                >
                  <Select placeholder="Select a category">
                    {categories.map((category) => (
                      <Option key={category._id} value={category._id}>{category.name}</Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  className='create-input'
                  label="Author"
                  name="author"
                  rules={[{ required: true, message: 'Please select an author!' }]}
                >
                  <Select placeholder="Select an author">
                    {authors.map((author) => (
                      <Option key={author._id} value={author._id}>{author.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
}

export default CreateBlogScreen;
