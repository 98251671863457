import { Select } from 'antd';
import React from 'react';
import { GoChevronDown } from "react-icons/go";


const { Option } = Select;

const KpiDropdown = () => {
  const items = [
    {
      key: '1',
      label: 'Overall Data',
    }
  ];

  return (
    <Select
      defaultValue="1"
      suffixIcon={<GoChevronDown />}
      className='custom-antd-select-box'
    >
      {items.map((item) => (
        <Option key={item.key} value={item.key}>
          {item.label}
        </Option>
      ))}
    </Select>
  );
};

export default KpiDropdown;
