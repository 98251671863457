import { Button, Form, Input } from 'antd';
import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../../components/Header';

const CreateCategoryScreen = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onFinish = async (values) => {
    try {
      setIsSubmitting(true);

      const userInfo = localStorage.getItem('userInfo');
      const token = userInfo ? JSON.parse(userInfo).token : null;

      if (!token) {
        throw new Error('No authentication token found');
      }

      const response = await fetch('https://gptsaas.aceva.com.my/api/blogCategories', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          name: values.name,
          slug: values.slug,
          description: values.description || ''
        })
      });

      const {data} = await response.json();

      console.log(data);

      if (!response.ok) {
        throw new Error(data.message || 'Failed to create category');
      }

      toast.success('Category created successfully!');
      navigate('/categories'); // Uncommented this line to redirect after success
    } catch (error) {
      console.error('Error creating category:', error);
      if (error.message === 'No authentication token found') {
        toast.error('Please login to create a category');
        navigate('/login');
      } else {
        toast.error(error.message || 'Failed to create category. Please try again.');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Header />
      <div className="list-of-blog-wrapper">
        <div className="container">
          <div className="flex justify-between items-center my-6">
              <h2 className="text-2xl font-bold text-heading">Create New Category</h2>
                <Link 
                to="/categories"
                className="bg-brand hover:bg-secondary text-white px-4 py-2 rounded-md transition duration-200"
              >
                Back to Categories
              </Link>
          </div>
          <Form
            form={form}
            name="create_category"
            onFinish={onFinish}
            layout="vertical"
            validateTrigger="onBlur"
          >
            <Form.Item
              name="name"
              label="Category Name"
              rules={[
                { required: true, message: 'Please input the category name!' },
                { min: 2, message: 'Category name must be at least 2 characters long' },
                { max: 50, message: 'Category name cannot exceed 50 characters' }
              ]}
            >
              <Input placeholder="Enter category name" className="rounded-md" />
            </Form.Item>

            <Form.Item
              name="slug"
              label="Slug"
              rules={[
                { required: true, message: 'Please input the category slug!' },
                { min: 2, message: 'Slug must be at least 2 characters long' },
                { max: 50, message: 'Slug cannot exceed 50 characters' },
                {
                  pattern: /^[a-z0-9]+(?:-[a-z0-9]+)*$/,
                  message: 'Slug can only contain lowercase letters, numbers, and hyphens'
                }
              ]}
            >
              <Input placeholder="Enter category slug" className="rounded-md" />
            </Form.Item>

            <Form.Item
              name="description"
              label="Description"
              rules={[
                { max: 500, message: 'Description cannot exceed 500 characters' }
              ]}
            >
              <Input.TextArea 
                rows={4} 
                placeholder="Enter category description" 
                className="rounded-md" 
              />
            </Form.Item>

            <Form.Item>
              <Button 
                type="primary" 
                htmlType="submit"
                loading={isSubmitting}
                disabled={isSubmitting}
                className="bg-brand hover:bg-secondary text-white px-4 py-2 rounded-md transition duration-200 h-auto"
              >
                {isSubmitting ? 'Creating Category...' : 'Create Category'}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
};

export default CreateCategoryScreen;
