import React, { useState } from 'react';
import {formatDateToHumanReadable} from '../utils/helper'

function UserBasicOverview({user}) {
  const [isEditing, setIsEditing] = useState(false);
  const [editedEmail, setEditedEmail] = useState('info.mdborhan@gmail.com');
  const [editedPhone, setEditedPhone] = useState('+60123456789');
  const [editedPlan, setEditedPlan] = useState('Preemium');

  const handleEditClick = () => {
    // Enable edit mode and set the initial values
    setIsEditing(true);
    setEditedEmail('info.mdborhan@gmail.com');
    setEditedPhone('+60123456789');
    setEditedPlan('Preemium');
  };

  const handleSaveClick = () => {
    // Save the edited values and disable edit mode
    setIsEditing(false);
    // Perform actions to save the updated values (e.g., make an API call)
  };

  return (
    <>
      {/* basic overview */}
      <div className="user-details-card">
        <div className="user-details-header">
          <h3>{`${user.first_name ? user.first_name: "Name is not updated by user!"} ${user.last_name ? user.last_name : ""}`}</h3>
          {isEditing ? (
            <button className="btn btn-mini" onClick={handleSaveClick}>
              Save
            </button>
          ) : (
            <button className="btn btn-mini" onClick={handleEditClick}>
              Edit User
            </button>
          )}
        </div>
        <div className="user-details-description">
          <h4>Basic Info</h4>
          <div className="user-details-description-content">
            <div className="user-des-content-wrap">
              <span>Email: </span>
              {isEditing ? (
                <input
                  type="text"
                  value={editedEmail}
                  onChange={(e) => setEditedEmail(e.target.value)}
                />
              ) : (
                <span>{user.email}</span>
              )}
            </div>
            <div className="user-des-content-wrap">
              <span>Phone no: </span>
              {isEditing ? (
                <input
                  type="text"
                  value={editedPhone}
                  onChange={(e) => setEditedPhone(e.target.value)}
                />
              ) : (
                <span>{user.phone ? user.phone : 'N/A'}</span>
              )}
            </div>
            <div className="user-des-content-wrap">
              <span>Registered on: </span>
              <span>{formatDateToHumanReadable(user.createdAt)}</span>
            </div>
          </div>
          <div className="user-details-description-content">
            <div className="user-des-content-wrap">
                <span>Plan: </span>
                {isEditing ? (
                  <select
                    value={editedPlan}
                    onChange={(e) => setEditedPlan(e.target.value)}
                  >
                    <option value="Preemium">Preemium</option>
                    <option value="Standard">Standard</option>
                    <option value="Basic">Basic</option>
                  </select>
                ) : (
                  <span>{user.plan.name}</span>
                )}
              </div>
              <div className="user-des-content-wrap">
                <span>Plan validate till: </span>
                <span>{user.planValidation ? formatDateToHumanReadable(user.planValidation) : "N/A"}</span>
              </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserBasicOverview;
