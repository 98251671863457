  import { Button, DatePicker, Form, Input } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Header from '../../components/Header';

  const UpdateAuthorScreen = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { id } = useParams();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      fetchAuthorDetails();
    }, [id]);

    const fetchAuthorDetails = async () => {
      try {
        const userInfo = localStorage.getItem('userInfo');
        const token = userInfo ? JSON.parse(userInfo).token : null;

        if (!token) {
          throw new Error('No authentication token found');
        }

        const response = await fetch(`https://gptsaas.aceva.com.my/api/authors/${id}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch author details');
        }

        const {data} = await response.json();
        
        // Set form values with fetched data
        form.setFieldsValue({
          ...data,
          joinDate: data.joinDate ? dayjs(data.joinDate) : null
        });
      } catch (error) {
        console.error('Error fetching author details:', error);
        toast.error(error.message || 'Failed to fetch author details');
        navigate('/authors');
      } finally {
        setIsLoading(false);
      }
    };

    const onFinish = async (values) => {
      try {
        setIsSubmitting(true);
        
        const formattedValues = {
          ...values,
          joinDate: values.joinDate.format('YYYY-MM-DD')
        };

        const userInfo = localStorage.getItem('userInfo');
        const token = userInfo ? JSON.parse(userInfo).token : null;

        if (!token) {
          throw new Error('No authentication token found');
        }

        const response = await fetch(`https://gptsaas.aceva.com.my/api/authors/${id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify(formattedValues)
        });

        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.message || 'Failed to update author');
        }

        toast.success('Author updated successfully!');
        navigate('/authors');
      } catch (error) {
        console.error('Error updating author:', error);
        if (error.message === 'No authentication token found') {
          toast.error('Please login to update the author');
          navigate('/login');
        } else {
          toast.error(error.message || 'Failed to update author. Please try again.');
        }
      } finally {
        setIsSubmitting(false);
      }
    };

    if (isLoading) {
      return (
        <>
          <Header />
          <div className="flex justify-center items-center min-h-[60vh]">
            <div className="flex flex-col items-center">
              <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-brand"></div>
              <span className="ml-4 text-lg text-paragraph mt-4">Loading author details...</span>
            </div>
          </div>
        </>
      );
    }

    return (
      <>
        <Header />
        <div className="list-of-blog-wrapper">
          <div className="container">
            <div className="table-header">
              <div className="table-title">
                <h2>Update Author</h2>
              </div>
              <div className="table-right">
                <Link 
                  to="/authors"
                  className="bg-brand hover:bg-secondary text-white px-4 py-2 rounded-md transition duration-200"
                >
                  Back to Authors
                </Link>
              </div>
            </div>
            <Form
              form={form}
              name="update_author"
              onFinish={onFinish}
              layout="vertical"
              validateTrigger="onBlur"
            >
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  { required: true, message: 'Please input the author name!' },
                  { min: 2, message: 'Name must be at least 2 characters long' },
                  { max: 50, message: 'Name cannot exceed 50 characters' }
                ]}
              >
                <Input placeholder="Enter author name" className="rounded-md" />
              </Form.Item>

              <Form.Item
                name="email"
                label="Email"
                rules={[
                  { required: true, message: 'Please input the author email!' },
                  { type: 'email', message: 'Please enter a valid email address!' },
                  { max: 100, message: 'Email cannot exceed 100 characters' }
                ]}
              >
                <Input placeholder="Enter author email" className="rounded-md" />
              </Form.Item>

              <Form.Item
                name="biography"
                label="Biography"
                rules={[
                  { required: true, message: 'Please input the author biography!' },
                  { min: 10, message: 'Biography must be at least 10 characters long' },
                  { max: 500, message: 'Biography cannot exceed 500 characters' }
                ]}
              >
                <Input.TextArea rows={4} placeholder="Enter author biography" className="rounded-md" />
              </Form.Item>

              <Form.Item
                name="website"
                label="Website (Optional)"
                rules={[
                  { type: 'url', message: 'Please enter a valid URL!' },
                  { max: 200, message: 'Website URL cannot exceed 200 characters' }
                ]}
              >
                <Input placeholder="Enter website URL" className="rounded-md" />
              </Form.Item>

              <Form.Item
                name="facebookProfile"
                label="Facebook Profile (Optional)"
                rules={[
                  { type: 'url', message: 'Please enter a valid Facebook URL!' },
                  { max: 200, message: 'Facebook URL cannot exceed 200 characters' }
                ]}
              >
                <Input placeholder="Enter Facebook profile URL" className="rounded-md" />
              </Form.Item>

              <Form.Item
                name="twitterProfile"
                label="Twitter Profile (Optional)"
                rules={[
                  { type: 'url', message: 'Please enter a valid Twitter URL!' },
                  { max: 200, message: 'Twitter URL cannot exceed 200 characters' }
                ]}
              >
                <Input placeholder="Enter Twitter profile URL" className="rounded-md" />
              </Form.Item>

              <Form.Item
                name="linkedInProfile"
                label="LinkedIn Profile (Optional)"
                rules={[
                  { type: 'url', message: 'Please enter a valid LinkedIn URL!' },
                  { max: 200, message: 'LinkedIn URL cannot exceed 200 characters' }
                ]}
              >
                <Input placeholder="Enter LinkedIn profile URL" className="rounded-md" />
              </Form.Item>

              <Form.Item
                name="joinDate"
                label="Join Date"
                rules={[{ required: true, message: 'Please select the join date!' }]}
              >
                <DatePicker style={{ width: '100%' }} className="rounded-md" />
              </Form.Item>

              <Form.Item>
                <Button 
                  type="primary" 
                  htmlType="submit"
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  className="bg-brand hover:bg-secondary text-white px-4 py-2 rounded-md transition duration-200 h-auto"
                >
                  {isSubmitting ? 'Updating Author...' : 'Update Author'}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </>
    );
  };

  export default UpdateAuthorScreen;
