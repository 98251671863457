import { LoadingOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../../components/Header';

const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

const ListOfCategoryScreen = ({toggleLoggedInState}) => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const columns = [
    {
      title: 'Category Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Slug', 
      dataIndex: 'slug',
      key: 'slug',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Created Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date) => new Date(date).toLocaleDateString()
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '15px' }}>
          <Link style={{ fontSize: '15px' }} to={`/update-category/${record._id}`}>Edit</Link>
          <p 
            style={{ cursor: 'pointer', marginBottom: '0px', fontSize: '15px', color: 'firebrick' }} 
            onClick={() => handleDelete(record._id)}
          >
            Delete
          </p>
        </div>
      ),
    },
  ];

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const userInfo = localStorage.getItem('userInfo');
      const token = userInfo ? JSON.parse(userInfo).token : null;

      if (!token) {
        throw new Error('No authentication token found');
      }

      const response = await fetch('https://gptsaas.aceva.com.my/api/blogCategories', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      const {data} = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Failed to fetch categories');
      }

      setCategories(Array.isArray(data) ? data : []);
      setError(null);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching categories:', error);
      if (error.message === 'No authentication token found') {
        toast.error('Please login to view categories');
        navigate('/login');
      } else {
        setError(error.message || 'Failed to fetch categories');
        toast.error(error.message || 'Failed to fetch categories');
      }
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this category?')) {
      try {
        const userInfo = localStorage.getItem('userInfo');
        const token = userInfo ? JSON.parse(userInfo).token : null;

        if (!token) {
          throw new Error('No authentication token found');
        }

        const response = await fetch(`https://gptsaas.aceva.com.my/api/blogCategories/${id}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.message || 'Failed to delete category');
        }

        toast.success('Category deleted successfully');
        fetchCategories(); // Refresh the list
      } catch (error) {
        console.error('Error deleting category:', error);
        if (error.message === 'No authentication token found') {
          toast.error('Please login to delete categories');
          navigate('/login');
        } else {
          toast.error(error.message || 'Failed to delete category');
        }
      }
    }
  };

  if (loading) {
    return (
      <>
        <Header toggleLoggedInState={toggleLoggedInState} />
        <div className="flex justify-center items-center py-20">
          <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-brand"></div>
          <span className="ml-4 text-lg text-paragraph">Loading...</span>
        </div>
      </>
    );
  }

  if (error) {
    return (
      <>
        <Header toggleLoggedInState={toggleLoggedInState} />
        <div className="container">
          <div className="list-of-blog-wrapper">
            <div>Error: {error}</div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <Header toggleLoggedInState={toggleLoggedInState} />
      <div className="container">
        <div className="list-of-blog-wrapper">
            <div className="flex justify-between items-center my-6">
              <h2 className="text-2xl font-bold text-heading">Category List</h2>
                <Link 
                to="/create-category"
                className="bg-brand hover:bg-secondary text-white px-4 py-2 rounded-md transition duration-200"
              >
                Add New Category
              </Link>
            </div>
          </div>
          <Table 
            columns={columns}
            dataSource={Array.isArray(categories) ? categories.map(cat => ({ ...cat, key: cat._id })) : []} 
            pagination={{ pageSize: 10 }} 
          />
        </div>
    </>
  );
};

export default ListOfCategoryScreen;
