import { Button, Form, Input } from 'antd';
import React, { useState, useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Header from '../../components/Header';

const UpdateCategoryScreen = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const userInfo = localStorage.getItem('userInfo');
        const token = userInfo ? JSON.parse(userInfo).token : null;

        if (!token) {
          throw new Error('No authentication token found');
        }

        const response = await fetch(`https://gptsaas.aceva.com.my/api/blogCategories/${id}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        const { data } = await response.json();

        if (!response.ok) {
          throw new Error(data.message || 'Failed to fetch category');
        }

        form.setFieldsValue({
          name: data.name,
          slug: data.slug,
          description: data.description
        });
        setLoading(false);
      } catch (error) {
        console.error('Error fetching category:', error);
        if (error.message === 'No authentication token found') {
          toast.error('Please login to update category');
          navigate('/login');
        } else {
          toast.error(error.message || 'Failed to fetch category');
        }
        setLoading(false);
      }
    };

    fetchCategory();
  }, [id, form, navigate]);

  const onFinish = async (values) => {
    try {
      setIsSubmitting(true);

      const userInfo = localStorage.getItem('userInfo');
      const token = userInfo ? JSON.parse(userInfo).token : null;

      if (!token) {
        throw new Error('No authentication token found');
      }

      const response = await fetch(`https://gptsaas.aceva.com.my/api/blogCategories/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          name: values.name,
          slug: values.slug,
          description: values.description || ''
        })
      });

      const { data } = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Failed to update category');
      }

      toast.success('Category updated successfully!');
      navigate('/categories');
    } catch (error) {
      console.error('Error updating category:', error);
      if (error.message === 'No authentication token found') {
        toast.error('Please login to update a category');
        navigate('/login');
      } else {
        toast.error(error.message || 'Failed to update category. Please try again.');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  if (loading) {
    return (
      <>
        <Header />
        <div className="flex justify-center items-center py-20">
          <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-brand"></div>
          <span className="ml-4 text-lg text-paragraph">Loading...</span>
        </div>
      </>
    );
  }

  return (
    <>
      <Header />
      <div className="list-of-blog-wrapper">
        <div className="container">
          <div className="flex justify-between items-center my-6">
              <h2 className="text-2xl font-bold text-heading">Update Category</h2>
                <Link 
                to="/categories"
                className="bg-brand hover:bg-secondary text-white px-4 py-2 rounded-md transition duration-200"
              >
                Back to Categories
              </Link>
          </div>
          <Form
            form={form}
            name="update_category"
            onFinish={onFinish}
            layout="vertical"
            validateTrigger="onBlur"
          >
            <Form.Item
              name="name"
              label="Category Name"
              rules={[
                { required: true, message: 'Please input the category name!' },
                { min: 2, message: 'Category name must be at least 2 characters long' },
                { max: 50, message: 'Category name cannot exceed 50 characters' }
              ]}
            >
              <Input placeholder="Enter category name" className="rounded-md" />
            </Form.Item>

            <Form.Item
              name="slug"
              label="Slug"
              rules={[
                { required: true, message: 'Please input the category slug!' },
                { min: 2, message: 'Slug must be at least 2 characters long' },
                { max: 50, message: 'Slug cannot exceed 50 characters' },
                {
                  pattern: /^[a-z0-9]+(?:-[a-z0-9]+)*$/,
                  message: 'Slug can only contain lowercase letters, numbers, and hyphens'
                }
              ]}
            >
              <Input placeholder="Enter category slug" className="rounded-md" />
            </Form.Item>

            <Form.Item
              name="description"
              label="Description"
              rules={[
                { max: 500, message: 'Description cannot exceed 500 characters' }
              ]}
            >
              <Input.TextArea 
                rows={4} 
                placeholder="Enter category description" 
                className="rounded-md" 
              />
            </Form.Item>

            <Form.Item>
              <Button 
                type="primary" 
                htmlType="submit"
                loading={isSubmitting}
                disabled={isSubmitting}
                className="bg-brand hover:bg-secondary text-white px-4 py-2 rounded-md transition duration-200 h-auto"
              >
                {isSubmitting ? 'Updating Category...' : 'Update Category'}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
};

export default UpdateCategoryScreen;
