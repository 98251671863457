import { Button, Form, Input, Select } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../../components/Header';
import BreadCrumb from '../../components/blog/BreadCrumb';
import QuillEditor from '../../components/blog/QuillEditor';

const { Option } = Select;

function UpdateBlogScreen() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [categories, setCategories] = useState([]);
  const [authors, setAuthors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [featuredImage, setFeaturedImage] = useState('');
  const [blog, setBlog] = useState(null);

  useEffect(() => {
    fetchBlogDetails();
    fetchCategoriesAndAuthors();
  }, [id]);

  const fetchBlogDetails = async () => {
    try {
      const userInfo = localStorage.getItem('userInfo');
      const token = userInfo ? JSON.parse(userInfo).token : null;

      if (!token) {
        throw new Error('No authentication token found');
      }

      const response = await axios.get(`https://gptsaas.aceva.com.my/api/blogs/${id}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.data.success) {
        throw new Error('Failed to fetch blog details');
      }

      const blogData = response.data.data;
      setBlog(blogData);
      setFeaturedImage(blogData.featuredImage || ''); // Ensure featuredImage is set even if null

      // Set initial form values
      form.setFieldsValue({
        title: blogData.title,
        content: blogData.content,
        category: blogData.category?._id,
        author: blogData.author?._id,
        videoUrl: blogData.videoUrl,
        image: blogData.featuredImage // Add this line to set the image field
      });

    } catch (error) {
      console.error('Error fetching blog details:', error);
      toast.error('Failed to load blog details');
      navigate('/blogs');
    }
  };

  const uploadFileHandler = async (e) => {
    const file = e.target.files[0];
    
    // Validate file type
    const validTypes = ['image/jpeg', 'image/png', 'image/gif'];
    if (!validTypes.includes(file.type)) {
      toast.error('Please upload a valid image file (JPEG, PNG, or GIF)');
      return;
    }

    // Validate file size (max 5MB)
    if (file.size > 5 * 1024 * 1024) {
      toast.error('File size should not exceed 5MB');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append("upload_preset", "pewhglsn");
    formData.append("cloud_name", "dzo2vmjdy");

    try {
      setUploadLoading(true);
      const { data } = await axios.post('https://api.cloudinary.com/v1_1/dzo2vmjdy/image/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setFeaturedImage(data.url);
      form.setFieldsValue({ image: data.url }); // Update form field
      toast.success('Image uploaded successfully');
    } catch (error) {
      console.error(error);
      toast.error('Failed to upload image');
    } finally {
      setUploadLoading(false);
    }
  };

  console.log('blog', blog);

  const fetchCategoriesAndAuthors = async () => {
    try {
      const userInfo = localStorage.getItem('userInfo');
      const token = userInfo ? JSON.parse(userInfo).token : null;

      if (!token) {
        throw new Error('No authentication token found');
      }

      const [categoriesResponse, authorsResponse] = await Promise.all([
        fetch('https://gptsaas.aceva.com.my/api/blogCategories', {
          headers: { 'Authorization': `Bearer ${token}` }
        }),
        fetch('https://gptsaas.aceva.com.my/api/authors', {
          headers: { 'Authorization': `Bearer ${token}` }
        })
      ]);

      if (!categoriesResponse.ok || !authorsResponse.ok) {
        throw new Error('Failed to fetch data');
      }

      const [categoriesData, authorsData] = await Promise.all([
        categoriesResponse.json(),
        authorsResponse.json()
      ]);

      setCategories(categoriesData.data || []);
      setAuthors(authorsData.data || []);
    } catch (error) {
      console.error('Error fetching data:', error);
      if (error.message === 'No authentication token found') {
        toast.error('Please login to continue');
        navigate('/login');
      } else {
        toast.error('Failed to load categories and authors');
      }
    } finally {
      setLoading(false);
    }
  };

  const onFinish = async (values) => {
    try {
      setIsSubmitting(true);

      const userInfo = localStorage.getItem('userInfo');
      const token = userInfo ? JSON.parse(userInfo).token : null;

      if (!token) {
        throw new Error('No authentication token found');
      }

      if (!featuredImage) {
        throw new Error('Featured image is required');
      }

      const data = {
        title: values.title.trim(),
        content: values.content,
        category: values.category,
        author: values.author,
        featuredImage: featuredImage
      };

      if (values.videoUrl) {
        // Basic URL validation
        const urlPattern = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
        if (!urlPattern.test(values.videoUrl)) {
          throw new Error('Invalid video URL format');
        }
        data.videoUrl = values.videoUrl.trim();
      }

      const response = await axios.put(`https://gptsaas.aceva.com.my/api/blogs/${id}`, data, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.data.success) {
        throw new Error(response.data.message || 'Failed to update blog post');
      }

      toast.success('Blog post updated successfully!');
      navigate('/blogs');
    } catch (error) {
      console.error('Error updating blog post:', error);
      if (error.message === 'No authentication token found') {
        toast.error('Please login to update the blog post');
        navigate('/login');
      } else {
        toast.error(error.message || 'Failed to update blog post. Please try again.');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.error('Form validation failed:', errorInfo);
    toast.error('Please check all required fields');
  };

  if (loading) {
    return (
      <>
        <Header />
        <div className="flex justify-center items-center py-20">
          <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-brand"></div>
          <span className="ml-4 text-lg text-paragraph">Loading...</span>
        </div>
      </>
    );
  }

  return (
    <>
      <Header />
      <div className="create-section">
        <div className="container">
          <div className="breadcrumb-wrap">
            <BreadCrumb />
          </div>
          <div className="form-header">
            <h2>Update Blog Post</h2>
          </div>

          <Form
            form={form}
            name="update-blog"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <div className="create-container">
              <div className="create-wrap">
                <Form.Item
                  className='create-input'
                  label="Blog Title"
                  name="title"
                  rules={[
                    { required: true, message: 'Please input the title!' },
                    { min: 5, message: 'Title must be at least 5 characters long' },
                    { max: 200, message: 'Title cannot exceed 200 characters' },
                    { whitespace: true, message: 'Title cannot be empty spaces' }
                  ]}
                >
                  <Input placeholder="Enter the blog title" />
                </Form.Item>

                <Form.Item
                  className='create-input'
                  label="Video URL (Optional)"
                  name="videoUrl"
                  rules={[
                    { type: 'url', message: 'Please enter a valid URL!' },
                    { max: 500, message: 'URL is too long' }
                  ]}
                >
                  <Input placeholder="youtube.com/watch?v=example" />
                </Form.Item>

                <Form.Item
                  name="content"
                  rules={[
                    { 
                      required: true, 
                      message: 'Please input the content!',
                      validator: (_, value) => {
                        if (!value || value === '<p><br></p>' || value.trim() === '') {
                          return Promise.reject('Please input the content!');
                        }
                        return Promise.resolve();
                      }
                    }
                  ]}
                >
                  <QuillEditor />
                </Form.Item>

                <Form.Item>
                  <Button 
                    type="primary" 
                    htmlType="submit"
                    loading={isSubmitting}
                    disabled={isSubmitting || uploadLoading}
                  >
                    {isSubmitting ? 'Updating...' : 'Update'}
                  </Button>
                  <Button 
                    style={{ marginLeft: '10px' }} 
                    onClick={() => navigate('/blogs')}
                    disabled={isSubmitting}
                  >
                    Cancel
                  </Button>
                </Form.Item>
              </div>
              <div className="create-featured-image">
                <Form.Item
                  className='create-input'
                  label="Upload Featured Image"
                  name="image"
                  rules={[
                    { 
                      required: !featuredImage,
                      message: 'Please upload a featured image!' 
                    }
                  ]}
                >
                  <input
                    type="file"
                    onChange={uploadFileHandler}
                    accept="image/*"
                    disabled={uploadLoading}
                  />
                  {uploadLoading && <div>Uploading...</div>}
                  {featuredImage && (
                    <div style={{ marginTop: '10px' }}>
                      <img 
                        src={featuredImage} 
                        alt="Featured" 
                        style={{ maxWidth: '200px' }} 
                        onError={(e) => {
                          console.error('Image failed to load:', e);
                          e.target.src = 'fallback-image-url'; // Optional: provide fallback image
                        }}
                      />
                    </div>
                  )}
                </Form.Item>

                <Form.Item
                  className='create-input'
                  label="Category"
                  name="category"
                  rules={[{ required: true, message: 'Please select a category!' }]}
                >
                  <Select placeholder="Select a category">
                    {categories.map((category) => (
                      <Option key={category._id} value={category._id}>{category.name}</Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  className='create-input'
                  label="Author"
                  name="author"
                  rules={[{ required: true, message: 'Please select an author!' }]}
                >
                  <Select placeholder="Select an author">
                    {authors.map((author) => (
                      <Option key={author._id} value={author._id}>{author.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
}

export default UpdateBlogScreen;
