import { Button, DatePicker, Form, Input } from 'antd';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../../components/Header';

const CreateAuthorScreen = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onFinish = async (values) => {
    try {
      setIsSubmitting(true);
      
      // Format the date to match API requirements
      const formattedValues = {
        ...values,
        joinDate: values.joinDate.format('YYYY-MM-DD'),
        biography: values.biography,
        facebookProfile: values.facebookProfile,
        twitterProfile: values.twitterProfile,
        linkedInProfile: values.linkedInProfile
      };

      // Get token from localStorage
      const userInfo = localStorage.getItem('userInfo');
      const token = userInfo ? JSON.parse(userInfo).token : null;

      if (!token) {
        throw new Error('No authentication token found');
      }

      const response = await fetch('https://gptsaas.aceva.com.my/api/authors', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(formattedValues)
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Failed to create author');
      }

      toast.success('Author created successfully!');
      navigate('/authors');
    } catch (error) {
      console.error('Error creating author:', error);
      if (error.message === 'No authentication token found') {
        toast.error('Please login to create an author');
        navigate('/login');
      } else {
        toast.error(error.message || 'Failed to create author. Please try again.');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Header />
      <div className="list-of-blog-wrapper">
        <div className="container">
          <div className="table-header">
            <div className="table-title">
              <h2>Create New Author</h2>
            </div>
            <div className="table-right">
              <button className='default-btn'>
                <Link to="/authors">
                  Back to Authors
                </Link>
              </button>
            </div>
          </div>
          <Form
            form={form}
            name="create_author"
            onFinish={onFinish}
            layout="vertical"
            validateTrigger="onBlur"
          >
            <Form.Item
              name="name"
              label="Name"
              rules={[
                { required: true, message: 'Please input the author name!' },
                { min: 2, message: 'Name must be at least 2 characters long' },
                { max: 50, message: 'Name cannot exceed 50 characters' }
              ]}
            >
              <Input placeholder="Enter author name" />
            </Form.Item>

            <Form.Item
              name="email"
              label="Email"
              rules={[
                { required: true, message: 'Please input the author email!' },
                { type: 'email', message: 'Please enter a valid email address!' },
                { max: 100, message: 'Email cannot exceed 100 characters' }
              ]}
            >
              <Input placeholder="Enter author email" />
            </Form.Item>

            <Form.Item
              name="biography"
              label="Biography"
              rules={[
                { required: true, message: 'Please input the author biography!' },
                { min: 10, message: 'Biography must be at least 10 characters long' },
                { max: 500, message: 'Biography cannot exceed 500 characters' }
              ]}
            >
              <Input.TextArea rows={4} placeholder="Enter author biography" />
            </Form.Item>

            <Form.Item
              name="website"
              label="Website (Optional)"
              rules={[
                { type: 'url', message: 'Please enter a valid URL!' },
                { max: 200, message: 'Website URL cannot exceed 200 characters' }
              ]}
            >
              <Input placeholder="Enter website URL" />
            </Form.Item>

            <Form.Item
              name="facebookProfile"
              label="Facebook Profile (Optional)"
              rules={[
                { type: 'url', message: 'Please enter a valid Facebook URL!' },
                { max: 200, message: 'Facebook URL cannot exceed 200 characters' }
              ]}
            >
              <Input placeholder="Enter Facebook profile URL" />
            </Form.Item>

            <Form.Item
              name="twitterProfile"
              label="Twitter Profile (Optional)"
              rules={[
                { type: 'url', message: 'Please enter a valid Twitter URL!' },
                { max: 200, message: 'Twitter URL cannot exceed 200 characters' }
              ]}
            >
              <Input placeholder="Enter Twitter profile URL" />
            </Form.Item>

            <Form.Item
              name="linkedInProfile"
              label="LinkedIn Profile (Optional)"
              rules={[
                { type: 'url', message: 'Please enter a valid LinkedIn URL!' },
                { max: 200, message: 'LinkedIn URL cannot exceed 200 characters' }
              ]}
            >
              <Input placeholder="Enter LinkedIn profile URL" />
            </Form.Item>

            <Form.Item
              name="joinDate"
              label="Join Date"
              rules={[{ required: true, message: 'Please select the join date!' }]}
            >
              <DatePicker style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item>
              <Button 
                type="primary" 
                htmlType="submit"
                loading={isSubmitting}
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Creating Author...' : 'Create Author'}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
};

export default CreateAuthorScreen;
