import { Modal, Table } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../../components/Header';

function ListOfBlogs() {
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchBlogs();
  }, []);

  const fetchBlogs = async () => {
    try {
      const userInfo = localStorage.getItem('userInfo');
      const token = userInfo ? JSON.parse(userInfo).token : null;

      if (!token) {
        throw new Error('No authentication token found');
      }

      const response = await axios.get('https://gptsaas.aceva.com.my/api/blogs', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.data) {
        throw new Error('Failed to fetch blogs');
      }

      setBlogs(response.data.data || []);
    } catch (error) {
      console.error('Error fetching blogs:', error);
      if (error.message === 'No authentication token found') {
        toast.error('Please login to continue');
        navigate('/login');
      } else {
        toast.error('Failed to load blogs');
      }
    } finally {
      setLoading(false);
    }
  };

  console.log('blogs', blogs);

  const handleDelete = async (id) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this blog?',
      content: 'This action cannot be undone.',
      okText: 'Yes, delete it',
      okType: 'danger',
      cancelText: 'No, cancel',
      onOk: async () => {
        try {
          const userInfo = localStorage.getItem('userInfo');
          const token = userInfo ? JSON.parse(userInfo).token : null;

          if (!token) {
            throw new Error('No authentication token found');
          }

          const response = await fetch(`https://gptsaas.aceva.com.my/api/blogs/${id}`, {
            method: 'DELETE',
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });

          if (!response.ok) {
            throw new Error('Failed to delete blog');
          }

          toast.success('Blog deleted successfully');
          fetchBlogs(); // Refresh the list
        } catch (error) {
          console.error('Error deleting blog:', error);
          if (error.message === 'No authentication token found') {
            toast.error('Please login to continue');
            navigate('/login');
          } else {
            toast.error('Failed to delete blog');
          }
        }
      }
    });
  };

  const columns = [
    {
      title: 'Featured Image',
      dataIndex: 'featuredImage',
      key: 'featuredImage',
      render: (image) => image ? (
        <img 
          src={image} 
          alt="Featured" 
          style={{ width: '50px', height: '50px', objectFit: 'cover', borderRadius: '8px' }}
        />
      ) : 'No image',
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (text) => <span>{text}</span>,
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      render: (category) => category?.name || 'N/A',
    },
    {
      title: 'Author',
      dataIndex: 'author',
      key: 'author',
      render: (author) => author?.name || 'N/A',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date) => new Date(date).toLocaleDateString(),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '15px' }}>
          <Link style={{ fontSize: '15px' }} to={`/update-blog/${record._id}`}>Edit</Link>
          <p 
            style={{ cursor: 'pointer', marginBottom: '0px', fontSize: '15px', color: 'firebrick' }} 
            onClick={() => handleDelete(record._id)}
          >
            Delete
          </p>
        </div>
      ),
    },
  ];
  
  if (loading) {
    return (
      <>
        <Header />
        <div className="flex justify-center items-center py-20">
          <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-brand"></div>
          <span className="ml-4 text-lg text-paragraph">Loading...</span>
        </div>
      </>
    );
  }

  return (
    <>
      <Header />
      <div className="container">
        <div className="list-of-blog-wrapper">
          <div className="table-header">
            <div className="table-title">
              <h2>Article List</h2>
            </div>
            <div className="table-right">
              <button className='default-btn'>
                <Link to="/create-blog">
                  Add New Post
                </Link>
              </button>
            </div>
          </div>
          <Table 
            columns={columns} 
            dataSource={blogs} 
            pagination={{ pageSize: 10 }}
            rowKey="_id"
          />
        </div>
      </div>
    </>
  );
}

export default ListOfBlogs;
