import React, { useState } from 'react';
import { PiTrashLight } from 'react-icons/pi';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import { items } from '../../utils/data';
import './listOfUsers.css';
import Spinner from 'react-bootstrap/Spinner';

function Items({ currentItems, loading }) {
  return (
    <>
      <div className="table-container">
        <table>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Phone No.</th>
            <th>Plan</th>
            <th>Bot Count</th>
            <th>Action</th>
          </tr>
          {
            loading ? <Spinner animation="border" role="status" size='sm'/> : <tbody>
            {currentItems &&
              currentItems.map((item) => (
                <tr key={item._id}>
                  <td>{`${item.first_name} ${item.last_name}`}</td>
                  <td>{item.email}</td>
                  <td>{item.phone}</td>
                  <td>{item.plan.name}</td>
                  <td>{item.botCount}</td>
                  <td>
                    <div className="table-button">
                      <Link to={`/user-details/${item._id}`} onClick={() => localStorage.setItem('selectedUser', JSON.stringify(item))}>
                        <button className='form-button user-view-button'>
                            View
                        </button>
                      </Link>
                      <PiTrashLight />
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
          }
        </table>
      </div>
    </>
  );
}

export default function PaginatedItems({ itemsPerPage, users, loading }) {
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;

  const currentItems = users.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(users.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % users.length;
    setItemOffset(newOffset);
  };
  return (
    <>
      <div className="pagination-status">
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={4}
          marginPagesDisplayed={0}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          activeClassName="active"
        />
        <div>
          <p>
            Showing <span>{`${itemOffset + 1}-${endOffset}`}</span> out of <span>{users.length}</span>
          </p>
        </div>
      </div>
      <Items currentItems={currentItems} loading={loading}/>
    </>
  );
}
