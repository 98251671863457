

// export const apiUrl = "http://35.187.244.219:5000/api"
export const apiUrl = 'https://gptsaas.aceva.com.my/api';
export const fileUrl = 'https://api2.aceva.com.my/api/upload';
export const rootUrl = 'https://api2.aceva.com.my';
// export const apiUrl = "http://localhost:5000/api"
// export const fileUrl = 'http://localhost:5000/api/upload'
// export const rootUrl = 'http://localhost:5000'

const baseUrl = 'https://api.aceva.com.my';

export const chatUrl = `${baseUrl}/api/botInteract/chat`;
export const chatMyUrl = `${baseUrl}/api/botInteract/chat_my`;
export const getChatByUserId = `${baseUrl}/api/botInteract`;
export const symptomsUrl = `${baseUrl}/api/symptoms`;
export const kpisUrl = `${baseUrl}/api/appointment/kpis`;
export const notifications = `${baseUrl}/api/notifications`;


