import React from 'react';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
const BreadCrumb = () => (
  <Breadcrumb
    items={[
      {
        title: 'Home',
      },
      {
        title: <Link to="/blogs">Blogs</Link>,
      },
      {
        title: 'Create new blog',
      },
    ]}
  />
);
export default BreadCrumb;