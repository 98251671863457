import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom'
import axios from 'axios';
import { apiUrl } from '../Variables';
import Layout from '../components/Layout'
import { Link, useParams } from 'react-router-dom'
import { PiTrashLight } from 'react-icons/pi'
import UserBasicOverview from '../components/UserBasicOverview'
import toast from 'react-hot-toast';
import { formatDateToHumanReadable } from '../utils/helper';
import Spinner from 'react-bootstrap/Spinner';

function UserDetailScreen({toggleLoggedInState}) {

  let { userId } = useParams();
  const selectedUser = JSON.parse(localStorage.getItem('selectedUser'))
  const [loading, setLoading] = useState(true);
  const [details, setDetails] = useState([]);

  useEffect(() => {
    // Retrieve userInfo from localStorage
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));

    if (!userInfo || !userInfo.token) {
      console.error('User token not found.');
      return;
    }

    // Add headers to include JWT token
    const headers = {
      Authorization: `Bearer ${userInfo.token}`,
    };

    const featchData = async () => {
      setLoading(true);
      try {
        const res = await axios.get(`${apiUrl}/user/detailsCostReport/${userId}`, { headers });
        console.log(res)
        setDetails(res.data);
        setLoading(false)
      } catch (error) {
        setLoading(false);
        console.log('Kpis fetching fail:', error);
        toast.error(`Error: ${error?.message || error?.response?.data}`);
      }
    };
    featchData();
  }, [userId]);

  return (
    <>
      <Layout toggleLoggedInState={toggleLoggedInState}>
        <div className="user-details-container">

         <UserBasicOverview user={selectedUser}/>

          {/* basic chatbot info */}
          <div className="user-details-card">
            <div className="user-details-header border-unset">
              <h3>Basic Chatbot Info</h3>
            </div>
            <div className="user-details-description">
            <table>
              <tr>
                <th>Bot Name</th>
                <th>Create On</th>
                <th>Last Retrained</th>
                <th>Last Activity</th>
                <th>Action</th>
              </tr>
              {
                loading ? <Spinner animation="border" role="status" size='sm'/> : <tbody>
                  {
                    details.map(item =>  <tr>
                      <td>
                        <div>{item.name}</div>
                      </td>
                      <td>
                        <div>{formatDateToHumanReadable(item.createdAt)}</div>
                      </td>
                      <td>
                        <div>{formatDateToHumanReadable(item.updatedAt)}</div>
                      </td>
                      <td>
                        <div>{item.lastActivity ? formatDateToHumanReadable(item.lastActivity) : 'N/A'}</div>
                      </td>
                      <td>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            gap: '10px',
                          }}
                        >
                          <div>
                            <Link to="/user-details">
                              <button className="btn btn-mini">View Files</button>
                            </Link>
                          </div>
                          <PiTrashLight />
                        </div>
                      </td>
                    </tr>)
                  }
                </tbody>
              }
            </table>
            </div>
          </div>
          
          {/* Advanced Chatbot Info */}
          <div className="user-details-card">
            <div className="user-details-header border-unset">
              <h3>Advanced Chatbot Info</h3>
              <button className="btn btn-mini">Export as XLSX</button>
            </div>
            <div className="user-details-description">
            <table>
              <tr>
                <th>Bot Name</th>
                <th>Knowledge Size (MB)</th>
                <th>Knowledge length</th>
                <th>Total Input Token</th>
                <th>Total Output Token</th>
                <th>Total Conversations</th>
                <th>Whatsapp Cost</th>
              </tr>
              {
                loading ? <Spinner animation="border" role="status" size='sm'/> : <tbody>
              {
                  details.map(item =>  <tr>
                    <td>
                      <div>{item.name}</div>
                    </td>
  
                    <td>
                      <div>{item.knowledgeDataSize}</div>
                    </td>
  
                    <td>
                      <div>{item.knowledgeDataLength}</div>
                    </td>
  
                    <td>
                      <div>{item.inputToken}</div>
                    </td>
  
                    <td>
                      <div>{item.outputToken}</div>
                    </td>
  
                    <td>
                      <div>{item.chatCounts}</div>
                    </td>
  
                    <td>
                      <div>0</div>
                    </td>
                  </tr>)
                }
              </tbody>
            }
            </table>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default UserDetailScreen