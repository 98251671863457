import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import { Table } from 'antd';

function ListOfAuthorScreen() {
  const [authors, setAuthors] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchAuthors();
  }, []);

  const fetchAuthors = async () => {
    try {
      const userInfo = localStorage.getItem('userInfo');
      const token = userInfo ? JSON.parse(userInfo).token : null;

      if (!token) {
        throw new Error('No authentication token found');
      }

      const response = await fetch('https://gptsaas.aceva.com.my/api/authors', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch authors');
      }

      const data = await response.json();
      
      if (data && Array.isArray(data.data)) {
        const transformedData = data.data.map(author => ({
          key: author?._id?.toString() || Math.random().toString(),
          id: author?._id?.toString() || Math.random().toString(),
          name: author?.name || '',
          bio: author?.bio || '',
          numberOfPosts: author?.numberOfPosts || 0,
          joinDate: author?.joinDate || '',
          email: author?.email || '',
          website: author?.website || '',
          facebook: author?.facebook || '',
          twitter: author?.twitter || '',
          linkedin: author?.linkedin || ''
        }));
        setAuthors(transformedData);
      } else {
        console.error('Received data is not in expected format:', data);
        setAuthors([]);
        toast.error('Invalid data format received from server');
      }
    } catch (error) {
      console.error('Error fetching authors:', error);
      if (error.message === 'No authentication token found') {
        toast.error('Please login to view authors');
      } else {
        toast.error('Failed to fetch authors. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    if (!window.confirm('Are you sure you want to delete this author?')) {
      return;
    }

    const loadingToast = toast.loading('Deleting author...');

    try {
      const userInfo = localStorage.getItem('userInfo');
      const token = userInfo ? JSON.parse(userInfo).token : null;

      if (!token) {
        throw new Error('No authentication token found');
      }

      const response = await fetch(`https://gptsaas.aceva.com.my/api/authors/${id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to delete author');
      }

      setAuthors(prevAuthors => prevAuthors.filter(author => author.id !== id));
      toast.success('Author deleted successfully', { id: loadingToast });
    } catch (error) {
      console.error('Error deleting author:', error);
      if (error.message === 'No authentication token found') {
        toast.error('Please login to delete authors', { id: loadingToast });
      } else {
        toast.error('Failed to delete author. Please try again.', { id: loadingToast });
      }
    }
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Biography',
      dataIndex: 'bio',
      key: 'bio'
    },
    {
      title: 'Number of Posts',
      dataIndex: 'numberOfPosts',
      key: 'numberOfPosts'
    },
    {
      title: 'Date Joined',
      dataIndex: 'joinDate',
      key: 'joinDate',
      render: (date) => {
        const d = new Date(date);
        const month = d.toLocaleString('default', { month: 'short' });
        return `${d.getDate()} ${month}, ${d.getFullYear()}`;
      }
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <div className="flex justify-center items-center space-x-4">
          <Link 
            to={`/update-author/${record.id}`}
            className="text-brand hover:text-secondary"
          >
            Edit
          </Link>
          <button 
            onClick={() => handleDelete(record.id)}
            className={`text-danger hover:text-orange ${record.numberOfPosts > 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
            disabled={record.numberOfPosts > 0}
            title={record.numberOfPosts > 0 ? "Cannot delete author with existing posts" : "Delete author"}
          >
            Delete
          </button>
        </div>
      )
    }
  ];
  
  return (
    <>
      <Header />
      <div className="container mx-auto px-4">
        <div className="max-w-7xl mx-auto py-6">
          <div className="flex justify-between items-center my-6">
            <h2 className="text-2xl font-bold text-heading">Author List</h2>
            <Link 
              to="/create-author"
              className="bg-brand hover:bg-secondary text-white px-4 py-2 rounded-md transition duration-200"
            >
              Add New Author
            </Link>
          </div>

          {loading ? (
            <div className="flex justify-center items-center py-20">
              <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-brand"></div>
              <span className="ml-4 text-lg text-paragraph">Loading...</span>
            </div>
          ) : (
            <Table 
              columns={columns}
              dataSource={authors}
              pagination={{
                pageSize: 10,
                showSizeChanger: false
              }}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default ListOfAuthorScreen;
